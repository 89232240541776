import parsePhoneNumber from "libphonenumber-js";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import Avatar from "../../components/Avatar";
import Heading from "../../components/Heading";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import { useModal } from "../../components/Modal/Modal";
import Page from "../../components/Page";
import { useAuth } from "../../contexts/UserContext";
import "./Account.scss";
import AccountForm from "./AccountForm";
import API from "../../services/api";
import Container from "../../components/Container";

const Account = () => {
  const modal = useModal();

  const [loading, setLoading] = useState(false);

  const { user, refetchUser } = useAuth();

  const mutation = useMutation({
    mutationFn: (data) => API.updateAccount(data),
    onSuccess: (data) => {
      modal.show();
      refetchUser();
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      console.error("Error creating check-in:", error);
    },
  });

  const userDetail = user?.data;
  const fullName = [userDetail?.firstName, userDetail?.lastName].join(" ");

  const parsedPhoneNumber = userDetail?.phoneNumber
    ? parsePhoneNumber(userDetail?.phoneNumber)
    : {};

  const phonePrefix = parsedPhoneNumber?.countryCallingCode
    ? `+${parsedPhoneNumber.countryCallingCode}`
    : "+61";
  const phoneNumber = parsedPhoneNumber?.nationalNumber ?? "";

  const initialValues = {
    preferredName: userDetail?.preferredName ?? "",
    firstName: userDetail?.firstName ?? "",
    lastName: userDetail?.lastName ?? "",
    email: userDetail?.email ?? "",
    phonePrefix,
    phoneNumber,
    country: userDetail?.country ?? "Australia",
  };

  const handleSubmit = ({
    preferredName,
    firstName,
    lastName,
    country,
    phonePrefix,
    phoneNumber,
  }) => {
    const payload = {
      uid: user?.uid,
      email: user?.email,
      phoneNumber: phoneNumber ? `${phonePrefix}${phoneNumber}` : undefined,
      preferredName,
      firstName,
      lastName,
      country,
      role: user?.data?.role,
    };
    setLoading(true);
    mutation.mutate(payload);
  };

  return (
    <Page hasBack>
      {userDetail ? (
        <Container className="Account">
          <div className="Account__header">
            <Avatar
              firstName={userDetail?.firstName}
              lastName={userDetail?.lastName}
              className="Account__avatar"
            />
            <Heading variant="xxl" className="Account__displayName">
              {fullName}
            </Heading>
            {/* <Link to="/new-password" className="Account__link">
              Change password
            </Link>
            <Link to="/new-email" className="Account__link">
              Change email
            </Link> */}
          </div>

          <div className="Account__form">
            <AccountForm
              loading={loading}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
          </div>
        </Container>
      ) : (
        <Loading />
      )}
      <Modal {...modal}>Updated successfully!</Modal>
    </Page>
  );
};

export default Account;
