import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckInContent from "../../hooks/useCheckInContent";
import CheckinAll from "./CheckinAll";
import CheckinIntroduce from "./CheckinIntroduce";
import Loading from "../../components/Loading";
import CheckinIdentityCheck from "./CheckinIdentityCheck";
// import CheckinResults from "./CheckinResults";
import CheckinResults from "../check-in-v2/CheckInResults";
import CheckInMobileView from "../check-in-v2/CheckInMobileView";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/UserContext";
import ChatBotComponent from "../../ChatBotComponent"

const getParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const Checkin = () => {
  /*eslint-disable-next-line no-unused-vars*/
  const identifier = getParam("email");
  const [isAnonymous] = useState(true);
  const [isSkip, setSkip] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const { isLoading, contentfulData } = useCheckInContent();
  const search = useLocation().search;
  const uidParam = new URLSearchParams(search).get("uid");
  const emailParam = new URLSearchParams(search).get("email");
  const orgParam = new URLSearchParams(search).get("organisation");
  const { user } = useAuth();

  const navigate = useNavigate();
  const [values, setValues] = useState({
    lifeWellbeing: 0,
    lifePerformance: 0,
    workWellbeing: 0,
    workPerformance: 0,
  });

  useEffect(() => {
    if (orgParam) {
      setOrg(orgParam)
    }
  },[orgParam])

  useEffect(() => {
    if (!email && emailParam) {
      setEmail(emailParam);
    }
    // else if (!email && !emailParam) {
    //   setEmail("anonymous@bennybutton.com");
    // }
  }, [emailParam, email]);

  const onStepSubmit = useCallback(
    (data) => {
      const totalSteps = 4;
      if (step < totalSteps - 1) {
        if (step === 1 && (uidParam || emailParam || user || isAnonymous)) {
          setStep(step + 2);
          if (isAnonymous && !emailParam && !user) {
            setEmail("_anonymous_@bennybutton.com")
          }
        } else {
          setStep(step + 1);
        }
      } else {
        setStep(1)
      }
      if (step === 2) {
        if (data) {
          setEmail(data);
        }
      } else {
        if (data) {
          setValues({ ...data });
        }
      }
    },
    [step, user, uidParam, emailParam, isAnonymous]
  );

  const onBack = useCallback(() => {
    if (step > 0) {
      if (step === 1) {
        navigate("/");
      } else {
        setStep(step - 1);
      }
    } else if (step === 0) {
      navigate("/");
    }
  }, [step, navigate]);

  // if (!user) {
  //   return <Loading isFullScreen={true} />
  // }

  if (isLoading) {
    return <Loading isFullScreen={true} />;
  }

  return (
    <>
      <Header />
      {step === 0 ? (
        <CheckinIntroduce
          onChangeSkip={() => {
            setSkip(!isSkip);
          }}
          contentfulData={contentfulData?.get("checkInStart")}
          onStepSubmit={onStepSubmit}
          // onBack={onBack}
        />
      ) : null}
      {step === 1 ? (
        <>
          <div className="CheckinAll__desktopView">
            <CheckinAll
              contentfulData={{
                lifeWellbeing: contentfulData?.get("lifeWellbeing"),
                lifePerformance: contentfulData?.get("lifePerformance"),
                workWellbeing: contentfulData?.get("workWellbeing"),
                workPerformance: contentfulData?.get("workPerformance"),
              }}
              initialData={values}
              // onBack={onBack}
              onStepSubmit={onStepSubmit}
            />
          </div>
          <div className="CheckinAll__mobileView">
            <CheckInMobileView
              contentfulData={{
                lifeWellbeing: contentfulData?.get("lifeWellbeing"),
                lifePerformance: contentfulData?.get("lifePerformance"),
                workWellbeing: contentfulData?.get("workWellbeing"),
                workPerformance: contentfulData?.get("workPerformance"),
              }}
              initialData={values}
              // onBack={onBack}
              onStepSubmit={onStepSubmit}
            />
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <CheckinIdentityCheck
          contentfulData={contentfulData?.get("checkInResults")}
          onBack={onBack}
          onStepSubmit={onStepSubmit}
        />
      ) : null}
      {step === 3 ? (
        <CheckinResults
          userIdentifier={identifier}
          values={values}
          contentfulData={contentfulData?.get("checkInResults")}
          onBack={onBack}
          onStepSubmit={onStepSubmit}
          email={email}
          org={org}
        />
      ) : null}
      <ChatBotComponent />
    </>
  );
};

export default Checkin;
