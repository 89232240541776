import "./App.css";
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  // Outlet
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import Header from './components/Header';
// import LandingPage from './pages/landing';
import CheckinPage from "./pages/check-in";
import SecurePage from "./pages/secure";
import Signin from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import ResetPassword from "./pages/reset-password";
import Resource from "./pages/resource";
import Confirmation from "./pages/confirmation";
import Account from "./pages/account";

// import NotFound from './pages/404';
import PrivateRoute from "./components/PrivateRoute";
import { useAuth, UserProvider } from "./contexts/UserContext";
import Loading from "./components/Loading";

// const NotFound = lazy(() => import('./pages/404'));
const queryClient = new QueryClient();

function App() {
  const IndexRoute = (props) => {
    const { userToken } = useAuth();

    if (userToken) {
      return <Navigate to="/" />;
    }
    return <Signin />;
  };

  return (
    <Suspense fallback={<Loading isFullScreen />}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Routes>
              <Route path="/" element={<CheckinPage />} />
              <Route
                path="/secure"
                element={
                  <PrivateRoute>
                    <SecurePage />
                  </PrivateRoute>
                }
              />
              <Route path="/sign-in" element={<IndexRoute />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/resource/:entryId" element={<Resource />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/account" element={<Account />} />
            </Routes>
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </Suspense>
  );
}

export default App;
