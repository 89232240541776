import React from 'react'

import TextField from '../TextField'
import prefixes from './prefixes.json'

import './PhoneNumberField.scss'

const sortedPrefixes = prefixes.sort((a, b) =>
  a.full_name_english > b.full_name_english ? 1 : -1,
)

const PhoneNumberField = ({ prefixProps = {}, ...props }) => {
  return (
    <div className="PhoneNumberField">
      <div className="PhoneNumberField__prefixSelect">
        <span className="PhoneNumberField__prefixSelectSingle">
          {prefixProps.value}
        </span>
        <select {...prefixProps}>
          {sortedPrefixes.map(({ id, dialling_code, full_name_english }) => (
            <option
              key={id}
              value={dialling_code}
              data-content={`${dialling_code} ${full_name_english}`}
            >
              {full_name_english} {dialling_code}
            </option>
          ))}
        </select>
      </div>
      <TextField
        className="PhoneNumberField__textField"
        autoComplete="off"
        {...props}
      />
    </div>
  )
}

export default PhoneNumberField
