import React from 'react'
import FormField from '../FormField'
import Select from '../Select/Select'
import './SelectField.scss'

const SelectField = ({
  options,
  id,
  name,
  onChange,
  value,
  defaultValue,
  required,
  disabled,
  ...props
}) => {
  return (
    <FormField error={true} {...props}>
      <Select
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className="SelectField__select"
      >
        {options?.map(({ label, value, isDisabled, isSelected }) => {
          return (
            <option
              key={value}
              value={value}
              disabled={isDisabled}
              defaultValue={isSelected}
            >
              {label}
            </option>
          )
        })}
      </Select>
    </FormField>
  )
}

export default SelectField
