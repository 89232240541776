import React, { useState } from "react";
import Button from "../../components/Button";
import StepLayout from "../../components/StepLayout";
import "./CheckinResults.scss";
import TextField from "../../components/TextField";

const CheckinIdentityCheck = React.memo(({ onStepSubmit, onBack }) => {
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    onStepSubmit && onStepSubmit(email);
  };

  const handleChange = (e) => {
    const email = e.target.value;
    if (!email) {
      setEmailValid(false);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
    setEmail(email);
  };

  return (
    <StepLayout hasBack={true} onBack={onBack}>
      <StepLayout.Title>Nearly there...</StepLayout.Title>
      <StepLayout.Description>Please enter your email to access your results and the Wellbeing Companion</StepLayout.Description>
      <div className="CheckinResults__identityContainer">
        <div className="CheckinResults__inputContainer">
          <TextField
            label="Please enter your email *"
            id="email"
            type="email"
            error={!emailValid ? "Email is invalid" : false}
            onChange={handleChange}
          />
          <div className="CheckinAll__mobileViewDisplay">
            <Button
              className="CheckinAll__buttonSubmitIdentity"
              onClick={onSubmit}
              disabled={!emailValid}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className="CheckinAll__desktopView">
        <StepLayout.Footer isFloat>
          <Button onClick={onSubmit} disabled={!emailValid}>
            Submit
          </Button>
        </StepLayout.Footer>
      </div>
    </StepLayout>
  );
});

export default CheckinIdentityCheck;
