import React, { useState, useEffect, useRef } from "react";

import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";
import Messages from "./Messages";
import Input from "./Input";
import axios from 'axios';
import "./styles/bot-styles.css";
import Header from "./Header";

function ChatBot( {session_id, customStyle} ) {
  const baseURL = process.env.REACT_APP_API_DOMAIN;

  const [messages, setMessages] = useState([]);
  const inpRef = useRef(null);
  const sendRef = useRef(null);
  const sliderRef = useRef(null);
  const tilesRef = useRef(null);
  const [text, setText] = useState("");
  const [unique, setUnique] = useState(0);

  const updateInput = (text) => {
    inpRef.current.value = text;
    setText(text);
  };

  const sendTileText = (tileText) => {
    send(tileText).then(r => console.log( "tile sent..." + r?.toString() ));
  };

  const fetchMessage = async (text) => {

    try {
      const response = await axios.post(`${baseURL}/v1/bot/dialogflow/query`, {
        question: text,
        session_id: session_id,
      });
      const answer = JSON.parse(response.data.body);
      return answer;

    } catch (error) {
      console.error("Error fetching message:", error);
      return "An error occurred while fetching the message.";
    }

  };

  useEffect(() => {

    async function loadWelcomeMessage() {

      setMessages([]);
      let k = unique;
      let response = await fetchMessage('Hey');
      if (response?.response_messages && response?.response_messages?.length > 0 ) {
          for (let i = 0; i < response.response_messages?.length; i++) {
              let answer = response.response_messages[i];
              let tiles = [];
              if (i + 1 === response.response_messages.length) {
                  tiles = response.tiles;
              }

              const botMessage = (
                  <BotMessage
                      key={k}
                      answer={answer}
                      msg_tiles={tiles}
                      onTileClick={sendTileText}
                      updateInput={updateInput}
                      sliderRef={sliderRef}
                      tilesRef={tilesRef}

                  />
              );

              setMessages((prevMessages) => [...prevMessages, botMessage]);
              k += 1;

              if (sliderRef.current) {
                  sliderRef.current.style.display = 'none';
              }
              if (tilesRef.current) {
                  tilesRef.current.style.display = 'none';
              }
          }
      }
      setUnique(k);
    }

    loadWelcomeMessage().then( ()=> {
            if ( process.env.NODE_ENV === 'development' ) {
                console.log( "A new chat conversation started with session-id: `" + session_id + "`..." );
            }
        }
    );
// eslint-disable-next-line    
  }, [session_id]);

  const send = async (text) => {

    let k = unique;
    const userMessage = <UserMessage key={k} text={text} />;
    k+=1;
    
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    if (sliderRef.current) {
      sliderRef.current.style.display = 'none';
    }
    if (tilesRef.current) {
      tilesRef.current.style.display = 'none';
    }

    let response = await fetchMessage(text);

    for (let i = 0; i < response.response_messages.length; i++) {
        let answer = response.response_messages[i];
        let tiles = [];
        if(i+1 === response.response_messages.length){
          tiles = response.tiles;
        }

        const botMessage = (
          <BotMessage
            key={k}
            answer={answer}
            msg_tiles={tiles}
            onTileClick={sendTileText}
            updateInput = {updateInput}
            sliderRef = {sliderRef}
            tilesRef={tilesRef}
            
          />
        );

        setMessages((prevMessages) => [...prevMessages, botMessage]);
    }
    setUnique(k);
  };

  return (
    <div style={customStyle} className="chatbot">
      <Header/>
      <Messages messages={messages}/>
      <Input onSend={send} inpRef={inpRef} text={text} setText={setText} sendRef={sendRef}/>
    </div>
  );
}

export default ChatBot;