import React from 'react'
import './Select.scss'

const Select = ({ className = '', children, ...props }) => {
  return (
    <select className={`Select ${className}`} {...props}>
      {children}
    </select>
  )
}

export default Select
