import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "../../components/Button";
import Form, { Legend } from "../../components/Form";
import Link from "../../components/Link";
import PhoneNumberField from "../../components/PhoneNumberField";
import TextField from "../../components/TextField";
import CountryField from "../sign-up/CountryField";

const REQUIRED_FIELD = "This field is required";

const validationSchema = Yup.object().shape({
  email: Yup.string().required(REQUIRED_FIELD).email("Invalid email"),
  preferredName: Yup.string().required(REQUIRED_FIELD),
  firstName: Yup.string().required(REQUIRED_FIELD),
  lastName: Yup.string().required(REQUIRED_FIELD),
});

const AccountForm = ({ initialValues, onSubmit, loading }) => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange: handleFieldChange,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleChange = (e) => {
    handleFieldChange?.(e);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <Legend>Personal Details</Legend>
        <TextField
          id="preferredName"
          label="Preferred name *"
          value={values.preferredName}
          error={errors.preferredName}
          onChange={handleChange}
        />
        <TextField
          id="firstName"
          label="First name *"
          value={values.firstName}
          error={errors.firstName}
          onChange={handleChange}
        />
        <TextField
          id="lastName"
          label="Last name *"
          value={values.lastName}
          error={errors.lastName}
          onChange={handleChange}
        />
        <TextField
          disabled
          id="email"
          label="Email *"
          type="email"
          value={values.email}
          error={errors.email}
          onChange={handleChange}
        />
        <PhoneNumberField
          id="phoneNumber"
          label="Phone"
          value={values.phoneNumber}
          error={errors.phoneNumber}
          onChange={handleChange}
          prefixProps={{
            id: "phonePrefix",
            value: values.phonePrefix,
            onChange: handleChange,
          }}
        />
        <CountryField
          id="country"
          label="Country"
          error={errors.country}
          value={values.country}
          onChange={handleChange}
        />
      </div>

      <Button
        isFull
        size="lg"
        type="submit"
        disabled={loading}
        className="Account__submit"
      >
        Save
      </Button>
      <Link href="https://bennybutton.com/termsandconditions" target="_blank">
        Terms and Conditions
      </Link>
    </Form>
  );
};

export default AccountForm;
