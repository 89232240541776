import React from 'react'
import Box from '../Box'
import './FormField.scss'

const FormField = ({ id, label, error, children, ...props }) => {
  return (
    <Box className="FormField">
      <label className="FormField__label" htmlFor={id}>
        {label}
      </label>
      {React.cloneElement(children, {
        className: `FormField__control ${
          error ? 'FormField__control--isError' : ''
        } ${children.props.className ?? ''}`,
      })}

      <Box className="FormField__error">{error}</Box>
    </Box>
  )
}

export default FormField
