import React from 'react'

import SelectField from '../../components/SelectField'
// import useGetCountries from '../../hooks/useGetCountries'
import countriesList from '../../fixtures/countries.json'
const CountryField = ({ ...props }) => {
  // const { data } = useGetCountries()
  const options = countriesList.map((country) => ({
    label: country,
    value: country,
  }))

  return <SelectField options={options} {...props} />
}

export default CountryField
